import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const useImageRotation = (initial, length, interval) => {
  const [current, setCurrent] = useState(initial);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrent((current + 1) % length);
    }, interval);
    return () => clearInterval(timer);
  }, [current, length, interval]);

  return [current, setCurrent];
};

const Gallery = () => {
  const images = [
    "https://res.cloudinary.com/dhvalxorx/image/upload/v1699327923/ArtBandit/DSCF4113_icp2fg.jpg",
    "https://res.cloudinary.com/dhvalxorx/image/upload/v1698949106/ArtBandit/DSCF1345_vvrx9n.jpg",
    "https://res.cloudinary.com/dhvalxorx/image/upload/v1698949106/ArtBandit/_DSF4779-2_sdcytl.jpg",
    "https://res.cloudinary.com/dhvalxorx/image/upload/v1699327923/ArtBandit/DSCF4120_nfsdlg.jpg",
    "https://res.cloudinary.com/dhvalxorx/image/upload/v1698949104/ArtBandit/_DSF4572_mlcdvp.jpg",
    "https://res.cloudinary.com/dhvalxorx/image/upload/v1699327924/ArtBandit/DSCF4104_tnkapf.jpg",
  ];

 const [current, setCurrent] = useImageRotation(1, images.length, 5000);

  // Preload images
  useEffect(() => {
    images.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  }, [images]);

  const nextImage = () => setCurrent((current + 1) % images.length);
  const prevImage = () =>
    setCurrent((current - 1 + images.length) % images.length);

  return (
    <div className='gallery-container'>
      <AnimatePresence mode='wait'>
        <motion.div className='image-wrapper' key={current}>
          <motion.img
            className='styled-img'
            key={current}
            src={images[current]}
            initial={{ opacity: 0.8 }}
            animate={{ opacity: 0.9 }}
            exit={{ opacity: 0.8 }}
            transition={{
              duration: 0.5,
              when: "beforeChildren",
              staggerChildren: 0.4,
            }}
          />
          <div className='backward-icon' onClick={prevImage}>
            ❮
          </div>
          <div className='forward-icon' onClick={nextImage}>
            ❯
          </div>
        </motion.div>
      </AnimatePresence>
    
    </div>
  );
};

export default Gallery;
