import React, { useState, useEffect } from "react";
import "../assets/styles/ScrollingText.css";

const ScrollingText = () => {
  const [currentIdx, setCurrentIdx] = useState(0);

  const items = [
    { main: "art", sub: "Explore top-notch art collections." },
    { main: "poster-", sub: "Distinctive posters that pop out." },
    { main: "fineart-", sub: "Enhance your area with fine art." },
    { main: "photo-", sub: "Seize life's events in high-res." },
    { main: "stickers-", sub: "Narrative stickers that engage." },
    { main: "prints-", sub: "High-quality prints for decor." },
    { main: "sculpture-", sub: "Intricate sculptures that inspire." },
    { main: "digital-", sub: "Digital art that captivates." },
    { main: "canvas-", sub: "Canvas pieces that impress." },
    { main: "abstract-", sub: "Abstract art that intrigues." },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIdx((prevIndex) => (prevIndex + 1) % items.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [items.length]);

  return (
    <div className='scroll-container'>
      {items.map((item, idx) => (
        <React.Fragment key={`fragment-${idx}`}>
          <div
            key={`text-item-${idx}`}
            className={`text-item ${
              currentIdx === idx ? "active" : "inactive"
            }`}
          >
            {`${item.main}bandit.co`}
          </div>
          <div
            key={`subtext-item-${idx}`}
            className={`subtext-item ${
              currentIdx === idx ? "active" : "inactive"
            }`}
          >
            {item.sub}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ScrollingText;
