import './App.css';
import Navbar from './components/Navbar';
import ScrollingText from "./components/ScrollingText";
import Gallery from "./components/Gallery";

function App() {
  return (
    <div className='App'>
      <ScrollingText />

      <Gallery />

    </div>
  );
}

export default App;
